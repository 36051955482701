
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const t = useI18n().t;

    return { t, router: useRouter() };
  },
});
